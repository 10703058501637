<template>
	<main>
		<div class="banner">
			<div class="swiper" ref="bannerSwiper" id="banner-swiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide" :style="{backgroundImage:'url('+item.image+')'}" :class="['item-'+index]" v-for="(item,index) in banner" :key="index">
						<div class="info">
							<div class="box">
								<div class="title">{{item.title}}</div>
								<div class="min-title">{{item.min}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="point">
					<div class="swiper-pagination"></div>
				</div>
			</div>
		</div>
		<div class="s1">
			<div class="center">
				<div class="list">
					<a-link to="/server" class="left item"><div class="box"><div class="icon"><i class="iconfont icon-shejiguifan"></i></div><div class="tit">品牌设计</div></div></a-link>
					<a-link to="/server" class="left item"><div class="box"><div class="icon"><i class="iconfont icon-wangzhan"></i></div><div class="tit">网站建设</div></div></a-link>
					<a-link to="/server" class="left item"><div class="box"><div class="icon"><i class="iconfont icon-xiaochengxushezhi"></i></div><div class="tit">小程序开发</div></div></a-link>
					<a-link to="/server" class="left item"><div class="box"><div class="icon"><i class="iconfont icon-appstoreadd"></i></div><div class="tit">APP开发</div></div></a-link>
					<a-link to="/server" class="left item"><div class="box"><div class="icon"><i class="iconfont icon-guanli"></i></div><div class="tit">定制系统开发</div></div></a-link>
					<a-link to="/server" class="left item"><div class="box"><div class="icon"><i class="iconfont icon-yunyingfuwu"></i></div><div class="tit">新媒体运营</div></div></a-link>
					<a-link to="/server" class="left item"><div class="box"><div class="icon"><i class="iconfont icon-SEO1"></i></div><div class="tit">企业网络排名</div></div></a-link>
					<a-link to="/server" class="left item"><div class="box"><div class="icon"><i class="iconfont icon-quanjing"></i></div><div class="tit">720°全景</div></div></a-link>
				</div>
			</div>
		</div>
		<div class="s2">
			<div class="center">
				<div class="title-box">
					<div class="title">作品案例</div>
				</div>
				<div class="content">
					<div class="category">
						<div class="cen">
							<div class="left item" :class="[!type ? 'active' : '']" @click="chooseType({id:0})">全部</div>
							<div class="left item" :class="[type === item.id ? 'active' : '']" v-for="(item,index) in type_list" :key="index" @click="chooseType(item)">{{item.title}}</div>
						</div>
					</div>
					<div class="list">
						<div class="box">
							<a-link :to="'/cases/detail?cases_id='+item.id" class="left block item" v-for="(item,index) in cases" :key="index">
								<div class="wrap">
									<div class="image">
										<img :src="item.image" :alt="item.title"/>
									</div>
									<div class="info">
										<div class="title">{{ item.title }}</div>
										<div class="tag"><span>{{ item.brand }}</span><template v-if="item.tag"><span v-for="(v,i) in tagText(item.tag)" :key="i">{{ v }}</span></template></div>
									</div>
								</div>
							</a-link>
						</div>
					</div>
					<div class="more">
						<a-link :to="'/cases?type='+type" class="btn">查看更多</a-link>
					</div>
				</div>
			</div>
		</div>
		<components-partner :partner_list="partner"/>
		<div class="s4">
			<div class="center">
				<div class="title-box">
					<div class=" title">行业资讯</div>
					<a-link to="/news/index" class=" more">查看更多</a-link>
				</div>
				<div class="content">
					<div class=" main">
						<a-link to="/news/detail" class="block item">
							<div class="image">
								<img :src="require('@/assets/image/a1.jpg')" alt=""/>
							</div>
							<div class="element-more title">四种网站可能被惩罚的情况</div>
							<div class="date-box"><span>建站知识</span><span>2020-11-11</span></div>
							<div class="element-more desc">这个世界是由不一样的标准构成的，而也恰好是这类标准的构建，才可以让每个岗位有纪律的开展或是进行，而网站优化同样如此。</div>
						</a-link>
					</div>
					<div class=" list">
						<a-link to="/news/detail" class="block item" v-for="(item,index) in 3" :key="index">
							<div class="left image">
								<img :src="require('@/assets/image/a1.jpg')" alt=""/>
							</div>
							<div class="left info">
								<div class="element-more title">四种网站可能被惩罚的情况</div>
								<div class="date-box"><span>建站知识</span><span>2020-11-11</span></div>
								<div class="element-more desc">这个世界是由不一样的标准构成的，而也恰好是这类标准的构建，才可以让每个岗位有纪律的开展或是进行，而网站优化同样如此。</div>
							</div>
						</a-link>
					</div>
				</div>
			</div>
		</div>
		<div class="empty"></div>
		<components-contact/>
		<components-video />
	</main>
</template>

<script>
import ComponentsPartner from "@/components/views/index/partner";
import ComponentsContact from "@/components/views/index/contact";
import ComponentsVideo from "@/components/views/common/Video"
import {cases,partner} from "@/api/views";
import {site_config} from "@/config";
import {wechatShare} from "@/common/share";
export default {
	components:{ComponentsPartner,ComponentsContact,ComponentsVideo},
	data(){
		return {
			banner:[
				{title:"互联网思维+创新模式，助力客户多方位升级！",min:"hu lian wang si wei +chuang xin mo shi ，zhu li ke hu duo fang wei sheng ji ！",image:"https://hnsw-home.oss-cn-beijing.aliyuncs.com/front/2.jpg"},
				{title:"提供全方位的数字化服务，助力客户一站上云！",min:"ti gong quan fang wei de shu zi hua fu wu ，zhu ke hu yi zhan shi shang yun ！",image:"https://hnsw-home.oss-cn-beijing.aliyuncs.com/front/1.jpg"},
				{title:"创造用户喜欢的体验，为客户持续创造价值",min:"chuang zao yong hu xi huan de ti yan ，wei ke hu chi xu chuang zao jia zhi ",image:"https://hnsw-home.oss-cn-beijing.aliyuncs.com/front/3.jpg"},
			],
			type_list:site_config.case_type,
			type:1,
			cases:[],
			partner:[]
		}
	},
	mounted() {
		this.swiperInit();
		this.getCasesList();
		this.getPartnerList();
		wechatShare.init();
	},
	methods:{
		getCasesList() {
			cases.all({
				type:this.type,
				page:1,
				limit:6
			}).then((res)=>{
				this.cases = res.list;
			})
		},
		getPartnerList(){
			partner.all().then((res)=>{
				this.partner = res.list;
			})
		},
		chooseType(item){
			this.type = item.id;
			this.getCasesList();
		},
		tagText(tag){
			return tag ? tag.split(',') : [];
		},
		swiperInit(){
			this.swiper_banner = new Swiper('#banner-swiper',{
				autoplay:{
					delay:3000,
					stopOnLastSlide: false,
					disableOnInteraction: true,
				},
				loop:true,
				watchOverflow:true,
				observer:true,
				observeParents:true,
				observeSlideChildren:true,
				pagination: {
					el: '#banner-swiper .swiper-pagination',
				},
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.empty{height:100px;}
@media screen and (max-width: 1220px){
	.empty{height:m(100px)}
}
@media screen and (max-width: 768px){
	.empty{height:x(100px)}
}
.banner{height:700px;overflow: hidden;
	.swiper{height:700px;
		.swiper-wrapper{
			.swiper-slide{height:700px;background-repeat: no-repeat;background-position: center center;background-size: cover;position: relative;
				.info{width: 1200px;height:inherit; margin: 0 auto;color:#fff;display: flex;align-items:center;text-align: center;
					.box{width:1200px;
						.title{font-size:56px;font-weight: bold;}
						.min-title{font-size:18px;margin-top: 20px;opacity: .9;text-transform: uppercase;}
					}
				}
			}
		}
		.point{position: absolute;bottom:0;height:40px; width:1200px;left:50%;transform:translateX(-50%);z-index:2;
			.swiper-pagination{bottom:20px;right:0;left:auto;
				::v-deep .swiper-pagination-bullet{opacity: 1;width: 20px;height:4px;background: #fff;margin-left: 10px;margin-right: 0; border-radius: 0;}
				::v-deep .swiper-pagination-bullet.swiper-pagination-bullet-active{background:$main;}
			}
		}
	}
}
@media screen and (max-width: 1220px) {
	.banner{height:m(700px);
		.swiper{height:m(700px);
			.swiper-wrapper{
				.swiper-slide{height:m(700px);
					.info{width: 100%;padding: 0 25px;
						.box{width: 100%;
							.title{font-size: m(56px);}
							.min-title{font-size: m(18px);margin-top: m(20px)}
						}
					}
				}
			}
			.point{width: 100%;
				.swiper-pagination{bottom:m(20px);right:25px;left:auto;
					::v-deep .swiper-pagination-bullet{width:m(20px);height:m(4px);margin-left:m(10px);}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.banner{height:x(700px);
		.swiper{height:x(700px);
			.swiper-wrapper{
				.swiper-slide{height:x(700px);
					.info{width: 100%;padding: 0 25px;
						.box{
							.title{font-size: x(56px);}
							.min-title{font-size: x(18px);margin-top: x(20px)}
						}
					}
				}
			}
			.point{width: 100%;
				.swiper-pagination{bottom:x(20px);right:25px;left:auto;
					::v-deep .swiper-pagination-bullet{width:x(20px);height:x(4px);margin-left:x(10px);}
				}
			}
		}
	}
}
.s1{padding: 80px 0 0;
	.center{width: 1200px;margin: 0 auto;
		.list{display: flex;flex-wrap: wrap;
			.item{width: 25%;height:140px;border-right: 1px solid $border;display: flex;align-items: center;justify-content: center;border-bottom: 1px solid $border;
				.box{text-align: center;
					.icon{color:$main;width: 50px;height:50px;margin: 0 auto;
						.iconfont{font-size: 30px;line-height: 1;}
					}
					.tit{font-size: 14px;margin-top: 10px;color:#333;}
				}
			}
			.item:nth-child(4n){border-right: 0;}
			.item:nth-last-child(1),.item:nth-last-child(2),.item:nth-last-child(3),.item:nth-last-child(4){border-bottom: none;}
		}
	}
}
@media screen and (max-width: 1220px) {
	.s1{padding: m(80px) 0 0;
		.center{width:100%;padding: 0 25px;
			.list{
				.item{width: 25%;height:m(140px);
					.box{
						.icon{width: m(50px);height:m(50px);
							.iconfont{font-size:m(34px);}
						}
						.tit{font-size:14px;margin-top:m(10px);}
					}
				}
				.item:nth-child(4n){border-right: 0;}
				.item:nth-last-child(1),.item:nth-last-child(2),.item:nth-last-child(3),.item:nth-last-child(4){border-bottom: none;}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.s1{padding: x(80px) 0 0;
		.center{width:100%;padding: 0 15px;
			.list{
				.item{width: 25%;height:x(140px);
					.box{
						.icon{width: x(50px);height:x(50px);
							.iconfont{font-size:x(34px);}
						}
						.tit{font-size:13px;margin-top:x(10px);}
					}
				}
			}
		}
	}
}
.s2{padding: 80px 0 0;
	.center{margin: 0 auto;
		.title-box{text-align: center;
			.title{font-size: 36px;line-height: 1; display: inline-block;position: relative;}
			.title::after{content: "";display: block;position: absolute; bottom:2px;width: 100%;height:4px;background: $main;z-index: 0;opacity: .6}
		}
		.content{
			.category{text-align: center;margin-top: 30px;
				.cen{display: inline-block;
					.item{padding: 0 0 0 15px;cursor: pointer;transition: all .3s ease;line-height: 1;}
					.item.active{color:$main;transition: all .3s ease;}
					@media (any-hover: hover) {
						.item:hover{color:$main;transition: all .3s ease;}
					}
					.item::after{content: "/";display: inline-block;margin-left: 15px;}
					.item:nth-last-child(1)::after{display: none;}
				}
			}
			.list{margin-top: 35px;
				.box{display: flex;flex-wrap: wrap;
					.item{width: 33.33%;padding: 15px;
						.wrap{background: #efefef;position: relative;cursor: pointer;overflow: hidden;
							.image{
								img{width: 100%;height:100%;object-fit: cover;object-position: top center;display: block;}
							}
							.info{position: absolute;bottom:0;left:0;padding: 30px;width: 100%;color:#fff;z-index: 2;transform: translateY(100%);transition: all .5s ease;
								.title{font-size: 24px;}
								.tag{margin-top: 10px;
									span{padding: 0 0 0 10px;opacity: .8;}
									span::after{content:"|";display:inline-block;margin-left: 10px;}
									span:nth-child(1){padding-left: 0;}
									span:nth-last-child(1)::after{display:none;}
								}
							}
						}
						.wrap::after{content:"";display: block;position: absolute;top:0;left:0;width: 100%;height:100%;background:$main;opacity:0;transition: all .5s ease;}
						@media (any-hover: hover) {
							.wrap:hover{
								.info{transform: translateY(0);transition: all .5s ease;}
							}
							.wrap:hover::after{opacity: .8;transition: all .5s ease;}
						}
					}
				}
				.box.active{display: flex;}
			}
			.more{margin-top: 35px;text-align: center;cursor: pointer;
				.btn{display: inline-block;color:#333; line-height: 1; text-align: center;margin: 0 auto;font-size: 18px;position: relative;}
				.btn::after{content:"";display: block;position: absolute;width: 100%;left:0; bottom:2px;height:2px;background: $main;opacity: .6;}
			}
		}
	}
}
@media screen and (max-width: 1220px) {
	.s2{padding: m(80px) 0 0;
		.center{width:100%;padding: 0 25px;
			.title-box{
				.title{font-size: 30px;}
			}
			.content{
				.category{margin-top:m( 30px);
					.cen{
						.item{font-size: 14px; padding: 0 0 0 m(15px);cursor: pointer;transition: all .3s ease;line-height: 1;}
						.item::after{margin-left:m(15px);}
					}
				}
				.list{margin-top: m(35px);
					.box{
						.item{padding:m( 15px);width: 50%;
							.wrap{
								.info{padding:m(20px);
									.title{font-size: 20px;}
									.tag{margin-top:m(10px);font-size: 14px;
										span{padding: 0 0 0 m(10px);}
										span::after{margin-left:m(10px);}
									}
								}
							}
							@media (any-hover: none) {
								.wrap{
									.info{transform: translateY(0);background:rgba(0,0,0,.6);}
								}
							}
						}
					}
				}
				.more{margin-top:m(35px);}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.s2{padding: x(80px) 0 0;
		.center{width:100%;padding: 0 15px;
			.title-box{
				.title{font-size: 24px;}
			}
			.content{
				.category{display: none;}
				.list{margin-top: m(35px);
					.box{
						.item{padding:m( 5px);width: 100%;
							.wrap{
								.info{padding:x(20px);
									.title{font-size: 14px}
									.tag{font-size: 12px;}
								}
							}
						}
						.item:nth-last-child(1),.item:nth-last-child(2){display: none;}
					}
				}
				.more{margin-top:x(35px);}
			}
		}
	}
}

.s4{padding: 80px 0 0;margin-bottom: 100px;display: none;
	.center{width: 1200px; margin: 0 auto;
		.title-box{text-align: center;overflow: hidden;display: flex;align-items: baseline;justify-content: space-between;
			.title{font-size: 36px;line-height: 1; display: inline-block;position: relative;}
			.title::after{content: "";display: block;position: absolute; bottom:2px;width: 100%;height:4px;background: $main;z-index: 0;opacity: .6}
			.more{display: inline-block;color:#333; font-size: 14px;position: relative;z-index: 2;transition: all .3s ease;}
			.more:hover{color:$main;transition: all .3s ease;}
		}
		.content{margin-top: 60px;overflow: hidden;display: flex;
			.main{width: 40%;margin-right: 30px;
				.item{
					.image{height:337px;
						img{width: 100%;height:100%;object-fit: cover;}
					}
					.title{width: 100%; font-size: 24px;margin-top: 15px;-webkit-line-clamp:1}
					.date-box{font-size: 14px;color:#999;margin-top: 15px;
						span:nth-child(1){color:#333;margin-right: 20px;}
					}
					.desc{margin-top: 15px;font-size: 14px;line-height: 24px;height:48px;color:#666;}
				}
			}
			.list{flex: 1;
				.item{display:flex;margin-bottom: 30px;
					.image{height:150px;width: 280px;
						img{width: 100%;height:100%;object-fit: cover;}
					}
					.info{flex: 1;margin-left: 30px;
						.title{width: 100%; font-size: 24px;-webkit-line-clamp:1}
						.date-box{font-size: 14px;color:#999;margin-top: 15px;
							span:nth-child(1){color:#333;margin-right: 20px;}
						}
						.desc{margin-top: 15px;font-size: 14px;line-height: 24px;height:48px;color:#666;}
					}
				}
				.item:nth-last-child(1){margin-bottom: 0;}
			}
		}
	}
}
@media screen and (max-width: 1220px){
	.s4{padding: m(80px) 0 0;margin-bottom: m(100px);
		.center{width:auto;padding: 0 25px;
			.title-box{
				.title{font-size: 30px;}
			}
			.content{margin-top:m(40px);
				.main{margin-right: m(30px);
					.item{
						.image{height:m(337px);}
						.title{font-size: 20px;margin-top: 15px;}
						.date-box{font-size: 14px;margin-top: 15px;
							span:nth-child(1){margin-right: 20px;}
						}
						.desc{margin-top: 15px;font-size: 14px;line-height: 24px;height:48px;}
					}
				}
				.list{
					.item{margin-bottom:m( 30px);
						.image{height:m(150px);width:m(280px);}
						.info{margin-left: m(30px);
							.title{font-size: 20px;}
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px){
	.s4{padding: x(80px) 0 0;margin-bottom: x(100px);
		.center{width:auto;padding: 0 15px;
			.title-box{
				.title{font-size: 24px;}
			}
			.content{margin-top:x(40px);
				.main{display: none;}
				.list{
					.item{margin-bottom:x( 20px);
						.image{height:x(180px);width:x(280px);}
						.info{margin-left: x(30px);
							.title{font-size: 16px;}
							.date-box{margin-top: 5px;}
							.desc{margin-top: 5px;line-height: 22px;font-size: 12px;}
						}
					}
				}
			}
		}
	}
}
</style>

