import {isDebug, isDev} from "@/common/other";
import {wxSdk} from "@/api/views.js";
import {site_config} from "@/config/index.js";

export const wechatShare = {
	config:{},
	init(config = {}){
		this.setConfig(config);
		console.log(config);
		if(isDev()){return false;}
		this.loadJs();
		this.ready();
	},
	setConfig(config){
		let url = window.location.href;
		if(url.indexOf('#') > -1){
			url = url.split('#')[0];
		}
		if(url.indexOf('?') > -1){
			url = url.split('?')[0]+window.location.hash;
		}else{
			url = url+window.location.hash;
		}

		config.link = url;
		if(!config.title){
			config.title = site_config.title
		}
		if(!config.desc){
			config.desc = "";
		}
		if(!config.imgUrl){
			config.imgUrl = window.location.origin+window.location.pathname+ 'front/img/share.png';
		}
		this.config = config;
	},
	loadJs(){
		let url = window.location.origin+window.location.pathname+window.location.search;
		wxSdk.jssdk({
			url:url
		}).then((res)=>{
			wx.config(res.config);
		})
	},
	ready(){
		if (typeof WeixinJSBridge == "undefined"){
			if( document.addEventListener ){
				document.addEventListener('WeixinJSBridgeReady', this.wxReady, false);
			}else if (document.attachEvent){
				document.attachEvent('WeixinJSBridgeReady', this.wxReady);
				document.attachEvent('onWeixinJSBridgeReady', this.wxReady);
			}else{

			}
		}else{
			this.wxReady()
		}
	},
	wxReady(){
		wx.ready(()=> {
			this.doShare();
		});
		this.doShare();
		return true;
	},
	doShare(){
		wx.updateAppMessageShareData({
			title: this.config.title,
			desc: this.config.desc,
			link:this.config.link,
			imgUrl: this.config.imgUrl,
			success : (msg)=>{
				console.log('onMenuShareAppMessage')
			},
			error: (res)=>{
				console.log(res);
			}
		});
		wx.updateTimelineShareData({
			title:this.config.title,
			link:this.config.link,
			imgUrl: this.config.imgUrl,
			success : (msg)=>{
				console.log('onMenuShareTimeline')
			},
			error: (res)=>{
				console.log(res);
			}
		});
	},
}
